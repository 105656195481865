import { Dialog, DialogContent, IconButton, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

type PreviewDialogProps = {
    open: boolean;
    handleOnClose: () => void;
    previewContent: string[];
};

const PreviewDialog = ({ open, handleOnClose, previewContent }: PreviewDialogProps) => {
    const isWideScreen = useMediaQuery("(min-width:800px)");
    return (
        <Dialog hideBackdrop open={open} onClose={() => handleOnClose()} fullScreen>
            <IconButton
                onClick={handleOnClose}
                style={{
                    position: "absolute",
                    top: isWideScreen ? "15px" : "2px",
                    right: isWideScreen ? "15px" : "2px",
                    zIndex: 2,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {previewContent.map((content, index) => (
                    <img
                        key={index}
                        src={content}
                        alt={`Page ${index + 1}`}
                        style={{
                            maxHeight: "100vh",
                            maxWidth: "100%",
                            width: "auto",
                            height: "auto",
                            display: "block",
                            margin: "auto",
                            padding: "10px",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            marginBottom: "16px",
                            objectFit: "contain",
                        }}
                    />
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default PreviewDialog;
