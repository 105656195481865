import { Box, styled } from "@material-ui/core";

const CarouselItemWrapper = styled(Box)(({ itemWidth }: { itemWidth: number }) => ({
    flex: `0 0 ${itemWidth}%`,
    maxWidth: "100%",
    boxSizing: "border-box",
    padding: "0 10px",

    "& img": {
        width: "100%",
        height: "auto",
        objectFit: "cover",
        "@media screen and (-webkit-min-device-pixel-ratio: 2)": {
            imageRendering: "-webkit-optimize-contrast",
        },
    },
}));

export default CarouselItemWrapper;
