import React, { ReactElement, useEffect, useState, useCallback } from "react";
import { Trans, useTranslation, withTranslation } from "react-i18next";

import { getUrlParams, getValidityForPreview } from "../../../utils/utils";
import { useBrand, useWindowSize } from "../../../utils/customHooks";
import { CERTIFICATE_ID_LENGTH } from "./utils/config";

// components
import LoadingOverlay from "../../Common/LoadingOverlay";
import InfoBadge from "../../Common/InfoBadge";
import CertificateValidationIcon from "./CertificateValidationIcon";
import CertificateNotFound from "./CertificateNotFound";
import UploadPDF from "./UploadPDF";

// material UI
import Grid, { GridDirection, GridSize } from "@material-ui/core/Grid";
import { Box, Divider, Typography, useMediaQuery } from "@material-ui/core";
import theme from "../../theme";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

// sharebuttons
import ShareButtons from "./ShareButtons/ShareButtons"; //ShareButtons for LinkeIn, FB, etc.

// redux
import { connect } from "react-redux";
import { getCertificateDetails } from "../../../actions/certificate_validator";
import { createMessage } from "../../../actions/messages";

// utils
import certificationValidatorSizeHandle from "./utils/styles"; //StyleSheet to set visualisation of Validator on difrent window-sizes
import EnlargeCertificate from "./EnlargeCertificate"; // Icon Enlarge Certificate
import LinkTranslated from "../../Common/LinkTranslated";
import RightArrowIcon from "../../../Assets/Images/Apps/CertificateValidator/RightArrowIcon";
import PagesCarousel from "./ImageCarousel/PagesCarousel";
// import PrintButton from "./utils/PrintHandler"; // Print Certificate Icon ***actual disabled***

interface Props {
    isCertificateLoading: boolean;
    certificateDetails: ICertificateDetails;
    getCertificateDetails: AGetCertificateDetails;
    createMessage: ACreateMessage;
    i18n: any;
}

type TContent = "invalid" | "not_found" | "certificate_details" | "";

const envIsInType = (_env: string | null): _env is TEnv => {
    if (!_env) return false;
    return ["local", "development", "prod", "staging"].includes(_env);
};

const getEnvParam = (_env: string | null): TEnv => {
    if (envIsInType(_env)) return _env;
    else return "prod";
};

function CertificateValidator(props: Props): ReactElement {
    // misc. hooks
    const { t } = useTranslation();
    // state hooks
    const [inputCredentialId, setInputCredentialId] = useState<string>("");
    const [btnDisabledCredentialId, setBtnDisabledCredentialId] = useState<boolean>(true);
    // GET-parameters from parent window (p_*). Send via message event
    const [p_credential, setCredential] = useState<string>("");
    const [p_env, setEnv] = useState<TEnv>("prod");
    // helper for initial load by message
    const [receivedMessage_Credential, setReceivedMessage_Credential] = useState<boolean>(false);
    const [receivedMessage_Config, setReceivedMessage_Config] = useState<boolean>(false);
    const [initiallyCalledBackend, setInitiallyCalledBackend] = useState<boolean>(false);
    // Variable to get URL from parent were iframe include
    const [parentUrl, setParentUrl] = useState("");
    // config
    const [config, setConfig] = useState<IValidatorConfig>({
        default_lg: "en",
        identifier: "",
    });
    const [loadedContent, setLoadedContent] = useState<TContent>("");
    const [resizeTimerFinished, setResizeTimerFinished] = useState<boolean>(false);
    const [certificateImageUrl, setCertificateImageUrl] = useState<string>("");

    // misc hooks
    const BrandManagerRef = useBrand();
    const [windowWidth] = useWindowSize();

    const over800 = useMediaQuery("(min-width:800px)");

    const hasPages = (props.certificateDetails?.certificate_pages?.length ?? 0) > 0;
    const previewScale = hasPages ? 1.4 : 1.2;

    const validatorContentRef = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                window.parent.postMessage(
                    [
                        "validatorContentRefHeight",
                        resizeTimerFinished || loadedContent !== "certificate_details"
                            ? node.getBoundingClientRect().height + 70
                            : certificationValidatorSizeHandle(windowWidth).contentHeight,
                    ],
                    "*"
                );
                if (!resizeTimerFinished && loadedContent === "certificate_details") {
                    var resizeTimer = setTimeout(() => {
                        var timedResizeValue = node.getBoundingClientRect().height + 70;
                        setResizeTimerFinished(true);
                        window.parent.postMessage(["validatorContentRefHeight", timedResizeValue], "*");
                        clearTimeout(resizeTimer);
                    }, 1000);
                }
                console.log(
                    "[CertificateValidator.validatorContentRef] - Resized",
                    node.getBoundingClientRect().height + 70
                );
                console.log("[CertificateValidator.loadedContent", loadedContent);

                return node;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [windowWidth, loadedContent, props.certificateDetails?.certificate_description]
    );

    /**
     * Initial use effect to add event listener for messages
     */
    useEffect(() => {
        // Event listener for messages is needed to receive the url search params from the parent
        window.addEventListener("message", onMessageReceivedFromParent);
        // timeout receiving after 32 secs
        let timout_duration = 32000;
        let loadTimeout = setTimeout(() => {
            setReceivedMessage_Credential(true);
            setReceivedMessage_Config(true);
        }, timout_duration);
        return () => {
            window.removeEventListener("message", onMessageReceivedFromParent);
            clearTimeout(loadTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * use Effect handler for language changes
     */
    useEffect(() => {
        // change language if default is set. Else use browser default
        if (config.default_lg) {
            if (["en", "de"].includes(config.default_lg)) props.i18n.changeLanguage(config.default_lg);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);

    /**
     * Helper function to get the api identifier from window object
     * @returns
     */
    const getApiIdentifier = () => {
        // get the api identifier from window
        let _api_identifier = config.identifier;
        if (!_api_identifier) {
            setReceivedMessage_Credential(true);
            return "";
        }
        return _api_identifier;
    };

    /**
     * Use effect to call the Backend
     */
    useEffect(() => {
        if (receivedMessage_Credential && receivedMessage_Config && !initiallyCalledBackend) {
            // call getCertificateDetails actions when credential id or env change
            props.getCertificateDetails(p_credential, getApiIdentifier(), p_env, BrandManagerRef.getAccountType());
            setInitiallyCalledBackend(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receivedMessage_Credential, receivedMessage_Config]);

    useEffect(() => {
        if (props.certificateDetails?.badge_url) {
            const randomNumber = Math.ceil(Math.random() * 1000);
            setCertificateImageUrl(
                `${
                    (props.certificateDetails.badge_url ?? "") +
                    (props.certificateDetails.badge_url.includes("?") ? "&" : "?")
                }${randomNumber}`
            );
        }
    }, [props.certificateDetails]);

    const onMessageReceivedFromParent = React.useCallback(
        (event) => {
            // console.log("[MESSAGE RECEIVED]", event);
            // always when a url message is received, update the state variables
            // event.data holds the parents search params, e.g.: ?credential=cer-...&env=local
            if (typeof event.data === "string" || event.data instanceof String) {
                if (event.data.startsWith("?")) {
                    // TODO The reason we need to do the replace is because of incosistencies in the url encoding in the backend (#2169)
                    let params = event.data.replace("%3d", "=").replace("%3D", "=");
                    // set certificate from get param "credential"
                    let _cred: string = getUrlParams(params).get("credential") || "";
                    setCredential(_cred);

                    // set environment from get param "env"
                    let _env: TEnv = getEnvParam(getUrlParams(params).get("env"));
                    if (window.location.hostname === "localhost") _env = "local";
                    setEnv(_env);

                    setReceivedMessage_Credential(true);
                } else if (event.data.startsWith("{")) {
                    // The config was posted to the frame
                    let _config = JSON.parse(event.data);
                    if ("identifier" in _config) setConfig(_config);
                    setReceivedMessage_Config(true);
                } else if (event.data.startsWith("http")) {
                    // Save Parent-URL
                    setParentUrl(event.data);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [p_credential, p_env]
    );

    const onChangeCredentialIdInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let val = event.currentTarget.value;
        val = val.replace(/[^A-Z0-9-]+/gi, "");
        setBtnDisabledCredentialId(!(val.length === CERTIFICATE_ID_LENGTH));
        setInputCredentialId(val);
    };

    const onSubmit = (event: React.FormEvent) => {
        // console.log("submitted");
        event.preventDefault();
        if (btnDisabledCredentialId) {
            const inputLength = inputCredentialId.length;
            if (inputLength < CERTIFICATE_ID_LENGTH)
                props.createMessage(
                    `${t("certificate_validator.not_found.messages.too_short")}: 12345678-1234-1234-1234-123456789abc`,
                    "info",
                    8000
                );
        } else {
            if (!inputCredentialId) {
                props.createMessage(t("certificate_validator.not_found.messages.invalid_input"), "info", 3000);
            }
            props.getCertificateDetails(inputCredentialId, getApiIdentifier(), p_env, BrandManagerRef.getAccountType());
        }
    };
    if (props.isCertificateLoading || !receivedMessage_Credential || !receivedMessage_Config) {
        // console.log(props.isCertificateLoading, receivedMessage_Credential, receivedMessage_Config)
        return <LoadingOverlay noMessage={true} />;
    } else if (!config.identifier) {
        return (
            <div
                key="improperly-configured"
                ref={validatorContentRef}
                style={{
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "fit-content",
                        margin: "auto",
                        color: theme.palette.error.main,
                        fontSize: "14px",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                    }}
                >
                    <ErrorOutlineOutlinedIcon style={{ marginRight: "12px" }} />
                    <div>
                        <p
                            ref={() => setLoadedContent("invalid")}
                            style={{
                                margin: "0px",
                            }}
                        >
                            {t("certificate_validator.misc.network_timeout")}
                        </p>
                        <p
                            ref={() => setLoadedContent("invalid")}
                            style={{
                                margin: "0px",
                            }}
                        >
                            {t("certificate_validator.misc.please_try_again")}
                        </p>
                    </div>
                </div>
            </div>
        );
    } else if (!props.certificateDetails) {
        // not found
        return (
            <div ref={() => setLoadedContent("not_found")}>
                <CertificateNotFound
                    validatorContentRef={validatorContentRef}
                    onSubmit={onSubmit}
                    inputCredentialId={inputCredentialId}
                    onChangeCredentialIdInput={onChangeCredentialIdInput}
                />
            </div>
        );
    } else {
        return (
            <div
                key="found"
                ref={validatorContentRef}
                style={{
                    margin: "auto",
                    width: certificationValidatorSizeHandle(windowWidth).maxContentWidth,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <Grid container direction="row">
                    {certificationValidatorSizeHandle(windowWidth).useTotalWidth === false ? <Grid item xs></Grid> : ""}
                    <Grid item xs={certificationValidatorSizeHandle(windowWidth).muiGridUseing as GridSize}>
                        <Grid container direction="column">
                            <div
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "#FFFFFF",
                                    overflow: "hidden",
                                    position: "relative",
                                    borderTopLeftRadius: "inherit",
                                    borderBottomLeftRadius: "inherit",
                                }}
                            >
                                <Grid item xs style={{ width: "100%" }}>
                                    <Box color={theme.palette.grey[900]} mt={1} ml={2} style={{ width: "100%" }}>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            style={{
                                                maxWidth: `calc(100% - ${over800 ? "280px" : "100px"})`,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {props.certificateDetails.event_name}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    {props.certificateDetails.is_demo_event ? (
                                        <InfoBadge
                                            text={t("certificate_validator.found.info_demo_certificate")}
                                            color={"#005CEF"}
                                            className="info-badge"
                                            style={{ paddingTop: 4, paddingBottom: 4 }}
                                        />
                                    ) : (
                                        <CertificateValidationIcon
                                            expiration_date={props.certificateDetails.expiration_date}
                                        />
                                    )}
                                </Grid>
                                <Box m={3}></Box>
                                <Divider />
                                <Box m={6}></Box>
                                <div>
                                    <Box color={theme.palette.grey[800]} fontFamily="Open Sans">
                                        <Grid
                                            container
                                            direction={
                                                certificationValidatorSizeHandle(windowWidth)
                                                    .certifPicToInfo as GridDirection
                                            }
                                            alignItems="center"
                                        >
                                            <Grid item xs>
                                                <div
                                                    style={{
                                                        margin: "auto",
                                                        maxHeight:
                                                            certificationValidatorSizeHandle(windowWidth)
                                                                .certifPicHeight[
                                                                props.certificateDetails.badge_format
                                                            ] * previewScale,
                                                        maxWidth:
                                                            certificationValidatorSizeHandle(windowWidth)
                                                                .certifPicDivWidth[
                                                                props.certificateDetails.badge_format
                                                            ] * previewScale,
                                                        position: "relative",
                                                    }}
                                                >
                                                    {hasPages ? (
                                                        <PagesCarousel
                                                            pages={props.certificateDetails.certificate_pages ?? []}
                                                        />
                                                    ) : (
                                                        <img
                                                            className={
                                                                props.certificateDetails.certificate_type === "badge"
                                                                    ? "center-block h-auto rounded mx-auto d-block"
                                                                    : "center-block h-auto rounded mx-auto d-block cv-certificate-shadow"
                                                            }
                                                            src={certificateImageUrl}
                                                            alt="Certificate of course"
                                                            style={{
                                                                maxWidth:
                                                                    certificationValidatorSizeHandle(windowWidth)
                                                                        .certifPicDivWidth[
                                                                        props.certificateDetails.badge_format
                                                                    ],
                                                            }}
                                                        />
                                                    )}

                                                    <EnlargeCertificate certificateDetails={props.certificateDetails} />
                                                </div>
                                            </Grid>
                                            <Grid item xs>
                                                <div
                                                    style={{
                                                        paddingLeft: 20,
                                                        paddingRight: 20,
                                                    }}
                                                >
                                                    <Grid container direction="column">
                                                        <Grid container direction="column">
                                                            <Box
                                                                fontWeight={400}
                                                                fontSize={
                                                                    certificationValidatorSizeHandle(windowWidth)
                                                                        .smallFont
                                                                }
                                                                mt={
                                                                    certificationValidatorSizeHandle(windowWidth)
                                                                        .infoToPicMargin
                                                                }
                                                            >
                                                                {t("certificate_validator.found.table_issued_for")}
                                                                {":"}
                                                            </Box>
                                                            <Box
                                                                fontWeight={700}
                                                                fontSize={
                                                                    certificationValidatorSizeHandle(windowWidth)
                                                                        .smallFont
                                                                }
                                                                mb={3}
                                                            >
                                                                {props.certificateDetails.certified_person}
                                                            </Box>
                                                        </Grid>

                                                        <Grid container direction="row">
                                                            <Grid item xs>
                                                                <Grid
                                                                    container
                                                                    direction={
                                                                        certificationValidatorSizeHandle(windowWidth)
                                                                            .issueAndExpireToDate as GridDirection
                                                                    }
                                                                >
                                                                    <Grid item xs>
                                                                        <Box
                                                                            fontWeight={
                                                                                theme.typography.fontWeightRegular
                                                                            }
                                                                            fontSize={
                                                                                certificationValidatorSizeHandle(
                                                                                    windowWidth
                                                                                ).smallFont
                                                                            }
                                                                        >
                                                                            {t(
                                                                                "certificate_validator.found.table_issue_date"
                                                                            )}
                                                                            :{" "}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Box
                                                                            fontWeight={700}
                                                                            fontSize={
                                                                                certificationValidatorSizeHandle(
                                                                                    windowWidth
                                                                                ).smallFont
                                                                            }
                                                                        >
                                                                            {props.certificateDetails.issue_date
                                                                                ? getValidityForPreview(
                                                                                      props.certificateDetails
                                                                                          .issue_date,
                                                                                      config.default_lg
                                                                                  )
                                                                                : ""}
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Grid
                                                                    container
                                                                    direction={
                                                                        certificationValidatorSizeHandle(windowWidth)
                                                                            .issueAndExpireToDate as GridDirection
                                                                    }
                                                                >
                                                                    {props.certificateDetails.expiration_date && (
                                                                        <>
                                                                            <Grid item xs>
                                                                                <Box
                                                                                    fontWeight={
                                                                                        theme.typography
                                                                                            .fontWeightRegular
                                                                                    }
                                                                                    fontSize={
                                                                                        certificationValidatorSizeHandle(
                                                                                            windowWidth
                                                                                        ).smallFont
                                                                                    }
                                                                                >
                                                                                    {t(
                                                                                        "certificate_validator.found.table_expiration_date"
                                                                                    )}
                                                                                    :{" "}
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                <Box
                                                                                    fontWeight={700}
                                                                                    fontSize={
                                                                                        certificationValidatorSizeHandle(
                                                                                            windowWidth
                                                                                        ).smallFont
                                                                                    }
                                                                                >
                                                                                    {getValidityForPreview(
                                                                                        props.certificateDetails
                                                                                            .expiration_date,
                                                                                        config.default_lg
                                                                                    )}
                                                                                </Box>
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction="column">
                                                            <Box
                                                                fontWeight={theme.typography.fontWeightRegular}
                                                                fontSize={
                                                                    certificationValidatorSizeHandle(windowWidth)
                                                                        .smallFont
                                                                }
                                                                mt={3}
                                                            >
                                                                {t("certificate_validator.found.issued_by")}
                                                                {":"}
                                                            </Box>
                                                            <Box
                                                                fontWeight={700}
                                                                fontSize={
                                                                    certificationValidatorSizeHandle(windowWidth)
                                                                        .smallFont
                                                                }
                                                                mb={3}
                                                            >
                                                                {props.certificateDetails.organisation_name}
                                                            </Box>
                                                        </Grid>
                                                        {props.certificateDetails.course_url && (
                                                            <Grid container direction="column">
                                                                <Box
                                                                    fontWeight={theme.typography.fontWeightRegular}
                                                                    fontSize={
                                                                        certificationValidatorSizeHandle(windowWidth)
                                                                            .smallFont
                                                                    }
                                                                >
                                                                    {t("certificate_validator.found.table_link")}:
                                                                </Box>
                                                                <Box
                                                                    fontWeight={700}
                                                                    fontSize={
                                                                        certificationValidatorSizeHandle(windowWidth)
                                                                            .smallFont
                                                                    }
                                                                    mb={3}
                                                                >
                                                                    <a
                                                                        href={
                                                                            props.certificateDetails.course_url.includes(
                                                                                "http://"
                                                                            ) ||
                                                                            props.certificateDetails.course_url.includes(
                                                                                "https://"
                                                                            )
                                                                                ? props.certificateDetails.course_url
                                                                                : `http://${props.certificateDetails.course_url}`
                                                                        }
                                                                        target="_new"
                                                                    >
                                                                        {props.certificateDetails.course_url}
                                                                    </a>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                        <Grid container direction="column">
                                                            <Box
                                                                fontWeight={theme.typography.fontWeightRegular}
                                                                fontSize={
                                                                    certificationValidatorSizeHandle(windowWidth)
                                                                        .smallFont
                                                                }
                                                            >
                                                                {t("certificate_validator.found.table_credential_id")}
                                                                {":"}
                                                            </Box>
                                                            <Box
                                                                fontWeight={700}
                                                                fontSize={
                                                                    certificationValidatorSizeHandle(windowWidth)
                                                                        .smallFont
                                                                }
                                                                mb={3}
                                                            >
                                                                <span>{props.certificateDetails.certificate_id}</span>
                                                            </Box>
                                                        </Grid>
                                                        <Grid container direction="column" style={{ minWidth: "0px" }}>
                                                            {parentUrl !== "" ? (
                                                                <ShareButtons
                                                                    sharedURL={parentUrl}
                                                                    certificateName={
                                                                        props.certificateDetails.event_name
                                                                    }
                                                                    certificationPicture={
                                                                        props.certificateDetails.badge_url
                                                                    }
                                                                />
                                                            ) : (
                                                                " "
                                                            )}
                                                        </Grid>
                                                        <Grid>
                                                            <br />
                                                            <UploadPDF
                                                                signingCertificateName={
                                                                    props.certificateDetails.key_vault_certificate_name
                                                                }
                                                                environment={p_env}
                                                            />
                                                        </Grid>
                                                        {props.certificateDetails.certificate_type === "badge" && (
                                                            <Grid>
                                                                <Box
                                                                    style={{ gap: 4 }}
                                                                    fontWeight={theme.typography.fontWeightRegular}
                                                                    fontSize={
                                                                        certificationValidatorSizeHandle(windowWidth)
                                                                            .smallFont
                                                                    }
                                                                    display="flex"
                                                                    alignItems="center"
                                                                >
                                                                    <LinkTranslated
                                                                        newTab
                                                                        moveTo="https://www.virtualbadge.io/resources/open-badges-validator"
                                                                    >
                                                                        {t(
                                                                            "certificate_validator.openbadges.openbadges_validator_link"
                                                                        )}
                                                                    </LinkTranslated>
                                                                    {t(
                                                                        "certificate_validator.openbadges.openbadges_validator_caption"
                                                                    )}
                                                                    <LinkTranslated
                                                                        newTab
                                                                        moveTo="https://www.virtualbadge.io/resources/open-badges-validator"
                                                                    >
                                                                        <RightArrowIcon />
                                                                    </LinkTranslated>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box m={9}></Box>
                                    <Box color={theme.palette.grey[900]} ml={2}>
                                        <Typography gutterBottom variant="h5">
                                            {t("certificate_validator.found.about_heading")}
                                        </Typography>
                                    </Box>
                                    <Box m={3}></Box>
                                    <Divider />
                                    <Box m={3}></Box>
                                    <div
                                        style={{
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            visibility: resizeTimerFinished ? "visible" : "hidden",
                                        }}
                                    >
                                        <Box color={theme.palette.grey[800]} mr={2}>
                                            {props.certificateDetails.certificate_description ? (
                                                <div
                                                    key="about-certificate-container"
                                                    className="mb-0 about"
                                                    dangerouslySetInnerHTML={{
                                                        __html: props.certificateDetails.certificate_description,
                                                        // sanitizeHtml(
                                                        //     props.certificateDetails.certificate_description
                                                        // ).replaceAll("<table>", '<table style="width: 100%;">'),
                                                    }}
                                                />
                                            ) : (
                                                <p className="mb-1 about">
                                                    <a
                                                        href={BrandManagerRef.getBrandUrl()}
                                                        target="_new"
                                                        className="link-style-normal "
                                                    >
                                                        {BrandManagerRef.getBrandName()}
                                                    </a>{" "}
                                                    {t("certificate_validator.found.about_default_text")}.
                                                </p>
                                            )}
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    {certificationValidatorSizeHandle(windowWidth).useTotalWidth === false ? <Grid item xs></Grid> : ""}
                </Grid>
                <div ref={() => setLoadedContent("certificate_details")} style={{ height: "0px" }}></div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    certificateDetails: state.certificate_validator.certificateDetails,
    certificateError: state.certificate_validator.certificateError,
    isCertificateLoading: state.loading.isCertificateLoading,
});

function mapDispatchToProps(dispatch: any) {
    return {
        getCertificateDetails: (
            certificate_id: string,
            api_identifier: string,
            env: TEnv,
            brandName: TAccountType,
            callback?: () => void
        ) => dispatch(getCertificateDetails(certificate_id, api_identifier, env, brandName, callback)),
        createMessage: (msg: string, severity: severity, duration: number = 5000) =>
            dispatch(createMessage(msg, severity, duration)),
    };
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CertificateValidator));
